import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import TotalPrice from "../../pages/Checkout/Components/RightContainer/Components/TotalPrice";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState } from "react";

const Cart = ({ setCartDrawerOpen }) => {
  const [cartDetails, setCartDetails] = useState([]);
  useEffect(() => {
    setCartDetails(
      localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : []
    );
  }, []);

  const removeProduct = useCallback(
    (index) => {
      const updatedData = cartDetails;
      updatedData.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(updatedData));
      setCartDetails(JSON.parse(localStorage.getItem("cart")));
    },
    [cartDetails]
  );

  const decreaseCount = useCallback(
    (index) => {
      const updatedData = cartDetails;

      if (updatedData[index]["quantity"] > 1) {
        updatedData[index]["quantity"] = updatedData[index]["quantity"] - 1;
        localStorage.setItem("cart", JSON.stringify(updatedData));
        setCartDetails(JSON.parse(localStorage.getItem("cart")));

        return;
      }
      removeProduct(index);
    },
    [cartDetails]
  );

  const increaseCount = useCallback(
    (index) => {
      const updatedData = cartDetails;
      updatedData[index]["quantity"] = updatedData[index]["quantity"] + 1;
      localStorage.setItem("cart", JSON.stringify(updatedData));
      setCartDetails(JSON.parse(localStorage.getItem("cart")));
    },
    [cartDetails]
  );
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        paddingLeft: {
          md: 10,
        },
        pb: 25,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={7}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: 30,
              textAlign: "center",
              color: "#282828",
              marginBottom: 20,
              paddingTop: 20,
            }}
          >
            Shopping Cart
          </Typography>
          <Divider sx={{ mt: 4, mb: 4 }} />
          <Grid container sx={{ ml: { md: 4 } }}>
            <Grid item xs={5} md={6}>
              <Typography
                sx={{ fontSize: { xs: 16, md: 20 }, ml: { xs: 3 } }}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                Product Details
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: { xs: 16, md: 20 }, ml: { xs: 3, md: 0 } }}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                Quantity{" "}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: { xs: 16, md: 20 }, ml: { xs: 3, md: 0 } }}
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                Total{" "}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 4, mb: 4 }} />
          {cartDetails?.map((item, index) => {
            return (
              <Grid container sx={{ mb: 5, ml: { md: 4 } }}>
                <Grid
                  item
                  xs={6}
                  md={6}
                  onClick={() => {
                    setCartDrawerOpen(false);
                    navigate(`/product-details/${item?.id}`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          backgroundColor: "#ECECEC",
                        }}
                        sx={{ ml: { xs: 2 } }}
                      >
                        <img
                          alt=""
                          src={item.image}
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        sx={{
                          fontSize: { xs: 13, md: 15 },
                          marginTop: { md: "20%" },
                        }}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                          color: "#000000",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xs: 13, md: 15 } }}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 400,
                          color: "#4B4B4B",
                        }}
                      >
                        {item.size}
                      </Typography>
                      <Typography
                        sx={{ fontSize: { xs: 13, md: 15 } }}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 400,
                          color: "#4B4B4B",
                        }}
                      >
                        {item.color}
                      </Typography>
                      {Object.keys(item.embriodery).length > 0 && (
                        <Typography
                          sx={{ fontSize: { xs: 13, md: 15 } }}
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: 400,
                            color: "#4B4B4B",
                          }}
                        >
                          CUSTOMIZED(+{item.customization_fees}LE)
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ paddingTop: "5%" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDiredction: "row",
                      columnGap: 10,
                    }}
                  >
                    <Typography
                      sx={{ fontSize: { xs: 20, md: 25 } }}
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        userSelect: "none",
                      }}
                      onClick={() => decreaseCount(index)}
                    >
                      -
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: 20, md: 20 } }}
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        userSelect: "none",
                      }}
                    >
                      {item.quantity}
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: 20, md: 25 } }}
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        userSelect: "none",
                        color:
                          item.product_initail_quantity < item.quantity + 1 &&
                          "grey",
                      }}
                      onClick={() => {
                        if (item.product_initail_quantity >= item.quantity + 1)
                          increaseCount(index);
                      }}
                    >
                      +{" "}
                    </Typography>
                  </Box>

                  <DeleteForeverOutlinedIcon
                    onClick={() => removeProduct(index)}
                    sx={{ fontSize: { xs: 25, md: 30 }, ml: 1 }}
                  />
                </Grid>
                <Grid item xs={3} style={{ paddingTop: "5%" }}>
                  <Typography
                    sx={{ fontSize: { xs: 20, md: 20 } }}
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                    }}
                  >
                    {item.quantity * item.price}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          style={{ backgroundColor: "#F6F6F6" }}
          sx={{
            mt: { md: 12 },
            ml: { md: 5 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                paddingTop: { xs: 1, md: 7 },
                fontSize: { xs: 23, md: 25 },
              }}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: " #282828",
              }}
            >
              ORDER SUMMARY
            </Typography>
          </Box>

          <Divider sx={{ m: 4 }} />
          <Box
            sx={{
              ml: 4,
            }}
          >
            <TotalPrice cartProducts={cartDetails} />
          </Box>
          <Box
            sx={{
              ml: 5,
              mr: 5,
              mt: { xs: 2, md: 6 },
              mb: 2,
            }}
          >
            <Button
              id="checkout-button"
              style={{
                backgroundColor: cartDetails.length == 0 ? "grey" : "black",
                color: "white",
              }}
              fullWidth
              disabled={cartDetails.length == 0}
              onClick={() => navigate("/checkout")}
            >
              Checkout
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Cart;
