export const handleAddToCart = (productId, price) => {
  if (typeof window !== "undefined" && window.fbq) {
    const fbclid = localStorage.getItem("fbclid");

    window.fbq("track", "AddToCart", {
      content_ids: [productId],
      content_type: "product",
      value: price,
      fbclid: fbclid || undefined,
    });
  }
};

// Track when a user initiates checkout
export const trackInitiateCheckout = (
  cartDetails,
  totalPrice,
  currency = "USD"
) => {
  if (typeof window !== "undefined" && window.fbq) {
  const fbclid = localStorage.getItem("fbclid");

  window.fbq("track", "InitiateCheckout", {
    value: totalPrice,
    num_items: cartDetails?.length || 0,
    content_type: "product",
    content_ids: cartDetails?.map((item) => item.id),
    content_names: cartDetails?.map((item) => item.name),

    currency: currency,
    fbclid: fbclid || undefined,
  });
  }
};

// Track successful purchase
export const trackPurchase = (
  orderId,
  totalPrice,
  currency = "USD",
  cartDetails
) => {
  if (typeof window !== "undefined" && window.fbq) {
    const fbclid = localStorage.getItem("fbclid");

    window.fbq("track", "Purchase", {
      value: totalPrice,
      currency: currency,
      transaction_id: orderId,
      content_ids: cartDetails?.map((item) => item.id),
      content_type: "product",
      num_items: cartDetails?.length || 0,
      fbclid: fbclid || undefined,
    });
  }
};

// Better session tracking with visibility change
export const trackSessionTime = () => {
  if (typeof window !== "undefined" && window.fbq) {
    const fbclid = localStorage.getItem("fbclid");

    const startTime = Date.now();

    const sendSessionTime = () => {
      const duration = Math.round((Date.now() - startTime) / 1000);
      window.fbq("trackCustom", "SessionDuration", {
        duration: duration,
        fbclid: fbclid || undefined,
      });
    };

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        sendSessionTime();
      }
    });

    window.addEventListener("beforeunload", sendSessionTime);
  }
};

// Store fbclid from URL params
export const storeFbclid = () => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get("fbclid");

    if (fbclid) {
      localStorage.setItem("fbclid", fbclid);
    }
  }
};

export const trackPageView = (pathname) => {
  if (window.fbq) {
    let pageType = "Other"; // Default

    if (pathname === "/") {
      pageType = "HomePage";
    } else if (pathname.startsWith("/products")) {
      pageType = "ProductPage";
    } else if (pathname.startsWith("/cart")) {
      pageType = "CartPage";
    } else if (pathname.startsWith("/checkout")) {
      pageType = "CheckoutPage";
    }

    // Track PageView with pageType
    window.fbq("track", "PageView", {
      content_name: pageType, // Meta uses 'content_name' for naming pages
      page: pathname, // Still keeping pathname for reference
    });
  }

  // Start session timer
  if (typeof window !== "undefined") {
    if (!window.sessionStartTime) {
      window.sessionStartTime = new Date().getTime();
    }
  }
};
